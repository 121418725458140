import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { UserService } from '../../../_services/user.service';
import { UserModel } from '../../../_models/user-model';
import { ContactUsService } from '../../../_services/contact-us.service';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { COMMONCODE, requestTypeListConst } from '../../../_helpers/constants';
import { CsrfTokenService } from '../../../_services/csrf-token.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    MatFormField,
    MatSelect,
    MatLabel,
    MatError,
    MatBottomSheetModule,
    ReactiveFormsModule,
    MatOption,
    MatHint,
    TextFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;
  csrfToken = '';
  loadingContactUsPost = false;
  loadingUserData = false;
  requestTypeList = requestTypeListConst;
  mailList?: string[] = [];
  invalidForm = true;
  name: string | undefined = '';
  companyName: string | undefined = '';
  maxMessage = 200;
  contactUsForm = this.fb.group({
    requestType: ['', [Validators.required]],
    mailToBeContacted: ['', [Validators.required]],
    text: ['', [Validators.required]],
  });
  personalContact = '';
  languageUser = '';
  isSuccess = false;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ContactUsComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private contactUserService: ContactUsService,
    private csrfTokenService: CsrfTokenService
  ) {}

  ngOnInit(): void {
    this.getAccountInfo();
    this.csrfTokenService
      .getCsrfToken()
      .subscribe((data) => (data.token = this.csrfToken));
  }

  getAccountInfo() {
    this.loadingUserData = true;
    this.userService.getAccountInfo().subscribe({
      next: (data: UserModel) => {
        this.loadingUserData = false;
        this.name = data.user?.name;
        this.companyName = data.user?.companyName;
        this.personalContact = data.user?.mail ?? '';
        this.languageUser = data.user?.language ?? '';
        data.user?.mail ? this.mailList?.push(data.user?.mail ?? '') : null;
      },
      error: (err) => {
        // console.error('Observable emitted an error: ' + err);
        this.loadingUserData = false;
      },
      complete: () => {
        this.loadingUserData = false;
      },
    });
  }

  onSubmit() {
    const commonCode = sessionStorage.getItem(COMMONCODE);
    if (commonCode) {
      this.getContactUs(commonCode);
    } else {
      this.cancel();
    }
  }

  getContactUs(commonCode: string) {
    this.loadingContactUsPost = true;
    this.contactUserService
      .getContactUs({
        mailToBeContacted: this.contactUsForm.value.mailToBeContacted,
        requestType: this.contactUsForm.value.requestType,
        text: this.contactUsForm.value.text,
        commonCode: commonCode,
        personalContact: this.personalContact,
        language: this.languageUser,
      })
      .subscribe({
        next: () => (this.loadingContactUsPost = false),
        error: (err) => {
          // console.error('Observable emitted an error: ' + err);
          this.loadingContactUsPost = false;
        },
        complete: () => {
          this.loadingContactUsPost = false;
          this.isSuccess = true;
        },
      });
  }

  cancel() {
    this._bottomSheetRef.dismiss();
  }
}
