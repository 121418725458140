<div class="contact-us-container">
  <h2 class="contact-us-title">
    {{ "contactUs.title" | translate | uppercase }}
  </h2>
  @if(loadingContactUsPost === true || loadingUserData === true) {
  <div class="spinner-container m-20">
    <mat-spinner [diameter]="60" class="fixed-spinner"></mat-spinner>
  </div>
  } @else if(isSuccess){
  <p class="success m-20 pt-20">{{ "contactUs.success" | translate }}</p>
  } @else {
  <div class="form-section">
    <p class="mt-10 mb-10">
      <b>{{ "contactUs.name" | translate }}</b
      >{{ ": " + name }}
    </p>
    <p class="mb-10">
      <b>{{ "contactUs.companyName" | translate }}</b
      >{{ ": " + companyName }}
    </p>
    <p class="mb-15">
      <b>{{ "contactUs.personalContact" | translate }}</b
      >{{ ": " + personalContact }}
    </p>

    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
      <input type="hidden" name="csrf-token" value="{{ csrfToken }}" />
      <div class="container-col">
        <mat-form-field class="request">
          <mat-label>{{ "contactUs.requestType" | translate }}</mat-label>
          <mat-select formControlName="requestType">
            @for (item of requestTypeList; track requestTypeList) {
            <mat-option [value]="item | translate">{{
              item | translate
            }}</mat-option>
            }
          </mat-select>
          @if(contactUsForm.controls.requestType.errors?.['required']){
          <mat-error>{{
            "registration.errors.required" | translate
          }}</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "contactUs.mailToBeContacted" | translate }}</mat-label>
          <mat-select formControlName="mailToBeContacted">
            @for (item of mailList; track mailList) {
            <mat-option [value]="item">{{ item }}</mat-option>
            }
          </mat-select>
          @if(contactUsForm.controls.mailToBeContacted.errors?.['required'] ||
          contactUsForm.controls.mailToBeContacted.touched){
          <mat-error>{{
            "registration.errors.required" | translate
          }}</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="mb-10">
          <mat-label>{{ "contactUs.text" | translate }}</mat-label>
          <!-- <textarea formControlName="text" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> -->
          <textarea
            matInput
            formControlName="text"
            [maxlength]="maxMessage"
            placeholder=""
            rows="5"
          ></textarea>
          <mat-hint align="end"
            >{{ contactUsForm.value.text?.length }} / {{ maxMessage }}</mat-hint
          >
          @if(contactUsForm.controls.text.errors?.['required']){
          <mat-error>{{
            "registration.errors.required" | translate
          }}</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="container-space-between">
        <button
          type="button"
          mat-flat-button
          class="secondary-btn"
          (click)="cancel()"
        >
          {{ "contactUs.cancel" | translate | uppercase }}
        </button>
        <button
          [disabled]="contactUsForm.invalid"
          type="submit"
          (click)="onSubmit()"
          mat-flat-button
          class="primary-btn"
        >
          {{ "contactUs.send" | translate | uppercase }}
        </button>
      </div>
    </form>
  </div>
  }
</div>
